import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import ProjectItem from "../components/projectItem"
import HeroHeader from "../components/heroheader"

class ProjectPage extends React.Component {
  render() {

    const projects = get(this, 'props.data.allNodeProject.edges');  


    return (
      <Layout>
        <SEO title="Tom Howard's projects" />

        <HeroHeader title="Projects" summary="A few projects I've completed"/>


          <TeaserList>
              {projects.map(({ node }) => {
                return (
                  <TeaserListItem key={node.path.alias}>
                    <ProjectItem item={node} />
                  </TeaserListItem>
                )
              })}
            </TeaserList>
          
      </Layout>
    )
  }
}

export default ProjectPage

export const pageQuery = graphql`
  query PortfolioItemsQuery {
    site {
      siteMetadata {
        title
      }
    }

    allNodeProject(sort: {fields: created, order: DESC}) {
      edges {
        node {
          title
          created(formatString: "dddd Do, MMMM YYYY")
          path {
            alias
          }
          field_link {
            uri
          }
          field_summary {
            processed
          }
          relationships {
            field_project_tags {
              name
            }
            field_images {
              localFile {
                publicURL
                childImageSharp {
                  # Specify a fixed image and fragment.
                  # The default width is 400 pixels
                  fixed(width: 700, height: 500) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            } 
            field_teaser_image {
              localFile {
                publicURL
                childImageSharp {
                  # Specify a fixed image and fragment.
                  # The default width is 400 pixels
                  fixed(width: 700, height: 500) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            } 
          }
        }
      }
    }
  }
`


const TeaserList = styled.ul`
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px;
`
const TeaserListItem = styled.li`
  list-style: none;
  flex: 100%;
  
  background: #F7F8FA;

  h2:last-child {
    margin-bottom: 15px;
  }

  @media(min-width: 600px) {
    flex: 0 0 49%;
  }

  @media(min-width: 740px) {
    flex: 0 0 30%;
  }
`